@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.App {
  text-align: center;
}

.custom-background {
  z-index: -1;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: repeat-x;
  position: fixed;
}

.custom-logo {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.custom-logo > img {
  height: auto;
  width: 237px;
  margin-top: 50px;
}

.custom-footer {
  color: #9fa4dd;
  line-height: 38px;
  font-weight: 400;
  text-decoration: none !important;
  position: fixed;
  left: 50%;
  bottom: 20px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  width: 210px;
  background: #00000045;
  padding: 1px 8px;
  border-radius: 12px;
}

.custom-footer > a > img {
  width: 118px;
  position: fixed;
  padding-left: 10px;
}
.custom-footer > img {
  width: 118px;
  position: fixed;
  padding-left: 10px;
}

.custom-footer > a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

button.my-custom-crossmint-button {
  width: 100%;
  margin-top: 10px;
}

#mui-1 {
  background: linear-gradient(90deg, rgba(96, 74, 229, 1) 0%, rgba(129, 62, 238, 1) 100%);
}

.ticket {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px;
  grid-gap: 70px;
  gap: 70px;

  max-width: 100%;
  height: 100%;
  /* Default/white */

  margin: 0 40px;
  background: #ffffff;
  /* /shadow/base */
  margin-top: 3rem;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.ticket-img {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 48px;
  gap: 48px;

  /* Inside auto layout */

  flex: 1 1;
  order: 0;
  max-width: 400px;
  border-radius: 19px;
  overflow: hidden;
}
.ticket-img-detail {
  width: 100%;
  height: 600px;
  border-radius: 19px;
  max-width: 100%;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ticket-content {
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 30px;
  gap: 30px;

  max-width: 50%;

  /* Inside auto layout */

  flex: 1 1;
  order: 1;
  flex-grow: 1;
}
.ticket-content-head {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 12px;
  gap: 12px;

  height: 68px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.ticket-content-head-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 11px;
  gap: 11px;

  height: 68px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
}
.ticket-content p {
  margin: 0;
}
.ticket-content-head-left > .title {
  font-family: "Roboto";
  font-style: normal;
  font-size: 35px;
  font-weight: 700;
  line-height: normal;
  color: #100820;
  margin: 0;
}
.ticket-content-head-left > .subtitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  /* Default/gray/500 */

  color: #6b7280;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.ticket-content-info {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 60px;
  gap: 60px;

  /* height: 60px; */

  flex-wrap: wrap;
  max-width: 100%;
  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.ticket-content-info-date {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 16px;
  gap: 16px;

  height: 60px;

  /* Inside auto layout */

  flex: 1 1;
  order: 0;
  align-items: baseline;
}
.ticket-content-info-date > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* gray/500 */

  color: #6b7280;

  white-space: break-spaces;
  max-width: 100%;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0;
}
.ticket-content-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  grid-gap: 30px;
  gap: 30px;

  /* Inside auto layout */
  height: auto;
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.ticket-content-description-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;

  /* #100820 */

  color: #100820;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.ticket-content-description-body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  /* gray/500 */

  color: #6b7280;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.ticket-content-currency {
  width: 124px;
  height: 41px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 41px;
  /* identical to box height */

  /* #100820 */

  color: #100820;

  /* Inside auto layout */

  flex: none;
  order: 3;
  flex-grow: 0;
  white-space: nowrap;
}
.ticket-content-connect {
  display: flex;
  order: 4;
}
.ticket-content-connect-button {
  position: relative;
  padding: 0 !important;
}

.ticket-content-connect-button-inner {
  /* #A066FF */

  background: #a066ff;
  /* shadow/sm */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 25px;

  width: 202px;
  height: 50px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* #F8F5FF */

  color: #f8f5ff;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.ticket-header {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 9px 20px;

  /* position: absolute; */
  width: calc(100% - 40px);
  height: 53.51px;
  left: 0px;
  top: 0px;

  /* Violet */

  background: #5831c5;
}
.ticket-header-image {
  width: 130px;
  height: 35.51px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  background-size: cover;
}
.ticket-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  grid-gap: 10px;
  gap: 10px;

  position: absolute;
  width: calc(100% - 40px);
  height: 60px;
  left: 0px;
  bottom: 0px;
  justify-content: space-between;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* #595959 */

  color: #595959;
  border-top: 1px solid #dcd3ff;
}
.ticket-footer-container {
  height: 100px;
  width: 100%;
  position: relative;
}
.ticket-footer-left > span {
  color: #833cfc;
}
.ticket-footer-right {
  display: flex;
  flex-flow: row;
  grid-gap: 12px;
  gap: 12px;
}
.ticket-footer-right > span {
  display: flex;
  flex: 1 1;
}

.ticket-mobile {
  display: none;
  flex-flow: column;
  height: 100vh;
  grid-gap: 20px;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 30px;
  gap: 30px;
}
.ticket-content-img {
  border-radius: 10px;
  overflow: hidden;
  height: 509.396px;
  width: 100%;
  order: 2;
  display: flex;
}
.ticket-content-img-detail {
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 425px) {
  .ticket {
    flex-flow: column;
  }
  .ticket-mobile {
    display: flex;
  }
  .ticket-img {
    display: none;
  }
  .ticket-content {
    display: none;
  }
  .ticket-content-head-left {
    grid-gap: auto;
    gap: auto;
    height: auto;
  }
  .ticket-content-info {
    flex-flow: column;
    grid-gap: 30px;
    gap: 30px;
  }
  .ticket-content-info-date {
    align-items: center;
  }
  .ticket-footer {
    position: static;
    margin-top: 20px;
    flex-flow: column;
    grid-gap: 10px;
    gap: 10px;
    height: auto;
    padding: 8px 20px;
  }

  .ticket-content-connect {
    width: 100%;
  }
  .ticket-content-connect button {
    width: 100%;
  }
}
@media (max-height: 475px) {
  .ticket-footer {
    position: relative;
  }
}

body {
  background: #f8f5fe;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

